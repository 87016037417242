import { useLocation, useNavigate } from 'react-router-dom';
import { ICustomLinkProps } from '../../Interfaces';
import { useLoading } from '../../Hooks/LoadingProvider';

export function CustomLink({ to, children }: ICustomLinkProps) {
    const { setIsLoading } = useLoading();
    const location = useLocation();
    const navigate = useNavigate();

    const handleClick = (event: { preventDefault: () => void; }) => {
        event.preventDefault();
        if (location.pathname !== `/${to.replace('/', '')}`) {
            setIsLoading(true);
            setTimeout(() => { navigate(to) }, 0);
        }
    };

    return (
        <a href={to} onClick={handleClick}>
            {children}
        </a>
    );
}
