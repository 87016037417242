import { IFile } from "../Interfaces"

export const handleOutsideIndex = (arr: string[], index: number): number => {
    if (!arr || arr.length === 0 || index < 0 || index >= arr.length) {
        return 0
    } else {
        return index
    }
}

export const handleSelectedDelete = (trainingSpotPictureIds: string[], selectedFiles: IFile[], id: string, setSelectedImageId: ((arg0: string) => void), selectedImageId?: string) => {
    if (id === selectedImageId) {
        if (trainingSpotPictureIds.length === 0 && selectedFiles.length === 0) {
            setSelectedImageId('')
        } else if (trainingSpotPictureIds.length === 0 && selectedFiles.length > 0) {
            setSelectedImageId(selectedFiles.map(x => x.id)[0])
        } else if (trainingSpotPictureIds.length > 0) {
            setSelectedImageId(trainingSpotPictureIds[0])
        }
    }
}