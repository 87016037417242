import React, { createContext, useContext, useEffect, useState } from 'react';
import { loadingTimeout } from '../../constants';

interface LoadingContextType {
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
    isVisible: boolean
    isLoading: boolean
}

const LoadingContext = createContext<LoadingContextType | undefined>(undefined);

export const useLoading = () => {
    const context = useContext(LoadingContext);
    if (!context) {
        throw new Error('useLoading must be used within a LoadingProvider');
    }
    return context;
};

export const LoadingProvider = ({ children }: any) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isVisible, setIsVisible] = useState<boolean>(true);

    useEffect(
        () => {
            if (isLoading) {
                setIsVisible(true)
                document.body.style.overflow = 'hidden';
            } else {
                setTimeout(
                    () => {
                        setIsVisible(false)
                        document.body.style.overflow = 'auto';
                    }, loadingTimeout
                )
            }
        }, [isLoading]
    )

    useEffect(() => {
        const handlePopState = () => {
            setIsVisible(true)
        };
        window.addEventListener('popstate', handlePopState);
        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, []);

    return (
        <LoadingContext.Provider value={{ setIsLoading, isVisible, isLoading }}>
            {children}
        </LoadingContext.Provider>
    );
};
