import React, { createContext, useContext } from "react";
import { localizedContent } from "../Content/content";
import { IGlobalContent } from "../Content/IContent";

interface ContentProviderProps {
  language: "en" | "pl" | "hu" | "lt";
  children: React.ReactNode;
}

const ContentContext = createContext<IGlobalContent | undefined>(undefined);

export const useContent = () => {
  const context = useContext(ContentContext);
  if (!context) {
    throw new Error("useContent must be used within a ContentProvider");
  }
  return context;
};

export const ContentProvider: React.FC<ContentProviderProps> = ({ language, children }) => {
  const content = localizedContent(language);

  if (!content) {
    console.error(`Invalid content structure for language ${language}`);
  }

  return <ContentContext.Provider value={content}>{children}</ContentContext.Provider>;
};
