import { useState } from "react";
import { apiURL } from "../../../constants";
import { IProfilePictureProps } from "../../Interfaces";
import defaultImage from '../../../Shared/Images/default-profile-pic.jpg';


export const ProfilePicture = ({ pictureId }: IProfilePictureProps) => {
    const [isLoaded, setIsLoaded] = useState(false)
    const thumbnailPath = pictureId?.slice(0, pictureId?.lastIndexOf('.')) +
        "-thumbnail" + pictureId?.slice(pictureId?.lastIndexOf('.'));

    return (
        <img
            src={pictureId ? !pictureId.startsWith('https') ? `${apiURL}/api/Images/${thumbnailPath}` : pictureId : defaultImage}
            referrerPolicy="no-referrer" 
            onLoad={() => { setIsLoaded(true) }}
            onError={(e) => { setIsLoaded(true) }}
            style={isLoaded ? { borderRadius: '50%', objectFit: 'cover', width: '3rem', height: '3rem' } : { borderRadius: '50%', objectFit: 'cover', width: '3rem', height: '3rem', backgroundColor: 'black' }}
            alt='User Profile'
        />
    )
}