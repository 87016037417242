import axios from "axios";
import { FormEvent, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { apiURL, defaultMapZoom, mapId, minZoom, skipTakeDefault } from "../../constants";
import { IReview, ITrainingSpot } from "../Interfaces";
import useErrorData from "../Hooks/ErrorData";
import { ErrorList } from "../Components/ErrorList/ErrorList";
import { useLoading } from "../Hooks/LoadingProvider";
import { TrainingSpotPicture } from "../Components/TraingSpotPicture/TrainingSpotPicture";
import { handleOutsideIndex } from "../Helpers/handleOutsideIndex";
import { useAuth } from "../Hooks/AuthProvider";
import { handleChange } from "../Helpers/UpdateFormData";
import { AddNewReviewForm } from "../Components/AddNewReviewForm/AddNewReviewForm";
import { initialReviewFormData, mapContainerStyle } from "../Data/InitialData";
import { AdvancedMarker, Map } from '@vis.gl/react-google-maps';
import { ProfilePicture } from "../Components/ProfilePicture/ProfilePicture";
import { Review } from "../Components/Review/Review";
import { LoadingSpinner } from "seb-components-library";
import { useContent } from "../Hooks/ContentProvider";
import { ITrainingSpotTagDictionary, ITrainingSpotTypesDictionary } from "../Content/IContent";

export const TrainingSpotPage = () => {
    let { trainingSpotId } = useParams();
    const [trainingSpot, setTrainingSpot] = useState<ITrainingSpot>()
    const [reviewFormData, setReviewFormData] = useState<IReview>(initialReviewFormData)
    const [errorData, setErrors] = useErrorData()
    const { setIsLoading } = useLoading();
    const [selectedImageId, setSelectedImageId] = useState<string>();
    const [isLoaded, setIsLoaded] = useState(false)
    const [canWriteReview, setCanWriteReview] = useState(false)
    const { user, token } = useAuth();
    const [editing, setEditing] = useState(false)
    // Reviews
    const [reviewData, setReviewData] = useState<IReview[]>([])
    const [reviewSkip, setReviewSkip] = useState<number>(0)
    const [isLoadingMoreReviews, setIsLoadingMoreReviews] = useState<boolean>(false)
    const [moreAvailable, setMoreAvailable] = useState<boolean>(true)
    const { trainingSpotPageContent, trainingSpotTagDictionary, trainingSpotTypesDictionary } = useContent();


    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true)
            try {
                const [getTrainingSpotData, getTrainingSpotReviews] = await Promise.all([
                    axios.get(`${apiURL}/api/TrainingSpots/${trainingSpotId}`),
                    axios.get(`${apiURL}/api/Review/${trainingSpotId}?skip=${reviewSkip}&take=${skipTakeDefault - 1}`, {
                        headers: {
                            Authorization: token
                        }
                    })
                ]);
                setTrainingSpot(getTrainingSpotData.data)
                // Set review data...
                setReviewSkip((prev) => { return (prev + skipTakeDefault - 1) })
                setReviewData(getTrainingSpotReviews.data)
                setMoreAvailable(getTrainingSpotReviews.data.length >= skipTakeDefault - 1)
                if (user && !getTrainingSpotReviews.data?.find((x: IReview) => x.userId === user?.sub) &&
                    getTrainingSpotData.data.userId !== user?.sub) {
                    setCanWriteReview(true)
                }
                const imageIndex = handleOutsideIndex(getTrainingSpotData.data.trainingSpotPictureIds, getTrainingSpotData.data.mainPictureIndex)
                setSelectedImageId(getTrainingSpotData.data.trainingSpotPictureIds[imageIndex]);
                setErrors([])
                setIsLoading(false)
            } catch (error: any) {
                setErrors(error?.response?.data?.errors)
                setIsLoading(false)
            }
        };

        fetchData();
        // eslint-disable-next-line
    }, []);

    const handleLoadMore = async () => {
        setIsLoadingMoreReviews(true)
        try {
            const response = await axios.get(`${apiURL}/api/Review/${trainingSpotId}?skip=${reviewSkip}&take=${skipTakeDefault}`, {
                headers: {
                    Authorization: token
                }
            });
            // Filter out existing IDs
            const existingIds = new Set(reviewData.map((review: IReview) => review.id));
            const filteredNewData = response.data.filter((review: IReview) => !existingIds.has(review.id));
            // Set review data...
            setReviewData((prev) => [...prev, ...filteredNewData]);
            setReviewSkip((prev) => prev + skipTakeDefault);
            setMoreAvailable(response.data.length >= skipTakeDefault)
        } catch (error: any) {
            setErrors(error?.response?.data?.errors);
        } finally {
            setIsLoadingMoreReviews(false)
        }
    };



    async function handleSubmit(event: FormEvent<HTMLFormElement>): Promise<void> {
        event.preventDefault()
        try {
            setIsLoading(true)
            const response = await axios.post(
                `${apiURL}/api/Review`, { ...reviewFormData, trainingSpotId: trainingSpotId }, {
                headers: {
                    Authorization: token
                }
            });
            setReviewData([...reviewData, response.data])
            trainingSpot && setTrainingSpot({ ...trainingSpot, currentRating: response.data.currentRating })
            setCanWriteReview(false)
            setErrors([])
        } catch (error: any) {
            setErrors(error.response.data.errors)
        } finally {
            setIsLoading(false)
        }
    }

    function handleUpdateFormData(review: IReview): void {
        setEditing(true);
        setReviewFormData({ ...initialReviewFormData, comment: review.comment, rating: review.rating })
    }

    async function handleDelete(id: string): Promise<void> {
        try {
            setIsLoading(true)
            const response = await axios.delete(
                `${apiURL}/api/Review/${id}`, {
                headers: {
                    Authorization: token
                }
            });
            if (response.status === 200) {
                const updatedReviews = reviewData.filter((review) => review.id !== response.data.id);
                setReviewData(updatedReviews);
                setReviewFormData(initialReviewFormData)
                setCanWriteReview(true);
                trainingSpot && setTrainingSpot({ ...trainingSpot, currentRating: response.data.currentRating })
                setErrors([])
            }
        } catch (error: any) {
            setErrors(error.response.data.errors)
        } finally {
            setIsLoading(false)
        }
    }

    async function handleEdit(e: { preventDefault: () => void; }, id: string): Promise<void> {
        e.preventDefault()
        try {
            setIsLoading(true)
            const response = await axios.put(
                `${apiURL}/api/Review/${id}`,
                {
                    id: id,
                    comment: reviewFormData.comment,
                    rating: reviewFormData.rating
                },
                {
                    headers: {
                        Authorization: token
                    }
                });
            if (response.status === 200) {
                trainingSpot && setTrainingSpot({ ...trainingSpot, currentRating: response.data.currentRating })
                setEditing(false)
                setReviewData(reviewData.map(review => review.id === id ? { ...review, ...response.data } : review))
                setReviewFormData(initialReviewFormData)
                setErrors([])
            }
        } catch (error: any) {
            setErrors(error.response.data.errors)
        } finally {
            setIsLoading(false)
        }
    }

    const trainingSpotTags = trainingSpot?.tagIds?.filter(item => {
        return trainingSpotTagDictionary.hasOwnProperty(item.toUpperCase());
    });

    const trainingSpotType = trainingSpot?.tagIds?.filter(item => {
        return trainingSpotTypesDictionary.hasOwnProperty(item.toUpperCase());
    });

    return (
      <div
        style={{ display: "flex", alignItems: "center", flexDirection: "column", gap: "0.5rem" }}
      >
        {trainingSpot?.title && <h2>{trainingSpot.title}</h2>}
        <p>
          {trainingSpotPageContent.rating} {trainingSpot?.currentRating}
        </p>
        {trainingSpotTags && trainingSpotTags?.length > 0 && (
          <>
            <h3>{trainingSpotPageContent.facilities}</h3>
            <ul>
              {trainingSpotTags.map((x) => {
                return (
                  <li key={x}>
                    {trainingSpotTagDictionary[x.toUpperCase() as keyof ITrainingSpotTagDictionary]}
                  </li>
                );
              })}
            </ul>
          </>
        )}
        {trainingSpotType && trainingSpotType?.length > 0 && (
          <>
            <h3>{trainingSpotPageContent.type}</h3>
            <ul>
              {trainingSpotType.map((x) => {
                return (
                  <li key={x}>
                    {
                      trainingSpotTypesDictionary[
                        x.toUpperCase() as keyof ITrainingSpotTypesDictionary
                      ]
                    }
                  </li>
                );
              })}
            </ul>
          </>
        )}
        {selectedImageId && (
          <img
            src={`${apiURL}/api/Images/${selectedImageId}`}
            onLoad={() => {
              setIsLoaded(true);
            }}
            onError={() => {
              setIsLoaded(true);
            }}
            style={
              isLoaded
                ? { height: "20rem", maxWidth: "90%", objectFit: "cover" }
                : { height: "20rem", backgroundColor: "black", maxWidth: "90%", objectFit: "cover" }
            }
            alt="Traing spot"
          />
        )}
        <div
          style={{
            display: "flex",
            gap: "0.5rem",
            maxWidth: "100%",
            overflow: "auto",
            padding: "2px",
          }}
        >
          {trainingSpot?.trainingSpotPictureIds.map((x) => {
            return (
              <div
                onClick={() => {
                  setSelectedImageId(x);
                }}
                key={x}
                style={{
                  outline: x === selectedImageId ? "2px solid green" : "none",
                  display: "flex",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                <TrainingSpotPicture pictureId={x} />
              </div>
            );
          })}
        </div>
        {trainingSpot?.posX && trainingSpot.posY && (
          <div style={mapContainerStyle}>
            <Map
              mapId={mapId}
              defaultCenter={{ lat: trainingSpot.posY, lng: trainingSpot.posX }}
              zoomControl={true}
              defaultZoom={defaultMapZoom}
              minZoom={minZoom}
              gestureHandling={"greedy"}
              disableDefaultUI={true}
            >
              <AdvancedMarker position={{ lat: trainingSpot.posY, lng: trainingSpot.posX }} />
            </Map>
          </div>
        )}
        <div style={{ display: "flex", gap: "0.25rem", alignItems: "center" }}>
          <ProfilePicture pictureId={trainingSpot?.user.profilePictureFileName} />
          <p>
            {trainingSpot?.user.name} (author) - {trainingSpot?.dateAdded.slice(0, 10)} -{" "}
            {trainingSpot?.comment} - rating: {trainingSpot?.rating}
          </p>
        </div>
        {reviewData?.map((review) => {
          return editing && review.userId === user?.sub ? (
            <AddNewReviewForm
              key={review.id}
              handleSubmit={handleSubmit}
              handleEdit={(e: { preventDefault: () => void }) => handleEdit(e, review.id)}
              handleChange={handleChange}
              setReviewFormData={setReviewFormData}
              reviewFormData={reviewFormData}
              editing={editing}
              setEditing={setEditing}
              headerLabel={trainingSpotPageContent.headerLabel}
              ratingLabel={trainingSpotPageContent.rating}
              submitLabel={trainingSpotPageContent.submitReviewLabel}
              cancelLabel={trainingSpotPageContent.cancelLabel}
            />
          ) : (
            <Review
              key={review.id}
              review={review}
              user={user}
              handleDelete={handleDelete}
              handleUpdateFormData={handleUpdateFormData}
              ratingLabel={trainingSpotPageContent.ratingLabel}
              editLabel={trainingSpotPageContent.editLabel}
              deleteLabel={trainingSpotPageContent.deleteLabel}
            />
          );
        })}
        {!isLoadingMoreReviews ? (
          moreAvailable && (
            <button onClick={handleLoadMore}>{trainingSpotPageContent.loadMore}</button>
          )
        ) : (
          <LoadingSpinner />
        )}

        <ErrorList errors={errorData} />
        {canWriteReview && (
          <AddNewReviewForm
            handleSubmit={handleSubmit}
            handleChange={handleChange}
            setReviewFormData={setReviewFormData}
            reviewFormData={reviewFormData}
            editing={editing}
            setEditing={setEditing}
            headerLabel={trainingSpotPageContent.headerLabel}
            ratingLabel={trainingSpotPageContent.rating}
            submitLabel={trainingSpotPageContent.submitReviewLabel}
            cancelLabel={trainingSpotPageContent.cancelLabel}
          />
        )}
      </div>
    );
}