import './App.css';
import { Routes, Route, Navigate } from "react-router";
import { RegisterPage } from './Shared/Pages/RegisterPage';
import { useLoading } from './Shared/Hooks/LoadingProvider';
import { LoadingSpinner } from 'seb-components-library';
import { LoginPage } from './Shared/Pages/LoginPage';
import { HomePage } from './Shared/Pages/HomePage';
import { useAuth } from './Shared/Hooks/AuthProvider';
import { ForgotPasswordPage } from './Shared/Pages/ForgotPasswordPage';
import { ResetPasswordPage } from './Shared/Pages/ResetPasswordPage';
import { MyTrainingSpots } from './Shared/Pages/MyTrainingSpotsPage';
import { AddNewTrainingSpotPage } from './Shared/Pages/AddNewTrainingSpotPage';
import { TrainingSpotPage } from './Shared/Pages/TrainingSpotPage';
import { CustomLink } from './Shared/Components/CustomLink/CustomLink';
import { AdminArea } from './Shared/Pages/AdminArea';
import { adminRole, roleClaim } from './constants';
import { MyProfile } from './Shared/Pages/MyProfile';
import { useContent } from './Shared/Hooks/ContentProvider';

export default function App() {
  const { isVisible } = useLoading();
  const { isLoggedIn, user } = useAuth();
  const { headerContent } = useContent();
  const isAdmin = (user && user[roleClaim] === adminRole)

  return (
    <div className="App">
      {isVisible && <div style={{
        position: 'fixed',
        backgroundColor: 'white',
        zIndex: '1000',
        width: '100%',
        height: "100%",
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}>
        <div style={{ margin: 'auto' }}>
          <LoadingSpinner />
        </div>
      </div>}
      <h1>Sports advisor</h1>
      <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
        <CustomLink to={'/'}>{headerContent.home}</CustomLink>
        {isLoggedIn &&
          <>
            <CustomLink to={'my-training-spots'}>{headerContent.myTrainingSpots}</CustomLink>
            <CustomLink to={'my-profile'}>{headerContent.myProfile}</CustomLink>
          </>
        }
        {isAdmin &&
          <CustomLink to={'admin-area'}>Admin area</CustomLink>
        }
        {!isLoggedIn && <>
          <CustomLink to={'login'}>{headerContent.login}</CustomLink>
          <CustomLink to={'register'}>{headerContent.register}</CustomLink>
          <CustomLink to={'forgot-password'}>{headerContent.forgotPassword}</CustomLink>
        </>}
      </div>
      <div style={{ padding: '0 0 2rem 0' }}>
        <Routes>
          <Route index element={<HomePage />} />
          <Route path='/reset-password' element={<ResetPasswordPage />} />
          <Route path='/:trainingSpotId' element={<TrainingSpotPage />} />
          <Route path='*' element={<Navigate to="/" replace />} />
          {isLoggedIn && <>
            <Route path='my-training-spots' element={<MyTrainingSpots />} />
            <Route path='add-new-training-spot' element={<AddNewTrainingSpotPage />} />
            <Route path='edit-training-spot/:trainingSpotId' element={<AddNewTrainingSpotPage />} />
            <Route path='my-profile' element={<MyProfile />} />
            <Route path='admin-area' element={<AdminArea />} />
          </>
          }
          {!isLoggedIn && <>
            <Route path='login' element={<LoginPage />} />
            <Route path='register' element={<RegisterPage />} />
            <Route path='forgot-password' element={<ForgotPasswordPage />} />
          </>}
        </Routes>
      </div>
    </div>
  );
}
