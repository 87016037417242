import { useState } from 'react';

const useErrorData = (): [string[], (error: string[]) => void] => {
    const [errorData, setErrorData] = useState<string[]>([]);

    const setErrors = (error: string[]) => {
        if (error && Array.isArray(error) && error?.length > 0) {
            setErrorData(error);
        } else if (error?.length === 0) {
            setErrorData([])
        } else {
            setErrorData(['Something went wrong, please try again later']);
        }
    };
    return [errorData, setErrors];
};

export default useErrorData;
