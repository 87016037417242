import type { Marker } from '@googlemaps/markerclusterer';
import { useCallback } from 'react';
import { AdvancedMarker } from '@vis.gl/react-google-maps';
import { IMapMarkers } from '../../Interfaces';

export type CustomMarkerProps = {
  coord: IMapMarkers;
  onClick: (marker: IMapMarkers) => void;
  setMarkerRef: (marker: Marker | null, key: string) => void;
};

export const CustomMarker = (props: CustomMarkerProps) => {
  const { coord, onClick, setMarkerRef } = props;

  const handleClick = useCallback(() => onClick(coord), [onClick, coord]);

  const ref = useCallback(
    (marker: google.maps.marker.AdvancedMarkerElement) =>
      setMarkerRef(marker, coord.id),
    [setMarkerRef, coord.id]
  );

  return (
    <AdvancedMarker position={{ lng: coord.posX, lat: coord.posY }} ref={ref} onClick={handleClick}>
      {/* <span className="marker-clustering-tree">🌳</span> */}
    </AdvancedMarker>
  );
};
