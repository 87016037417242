interface ErrorListProps {
    errors: string[];
}

export const ErrorList = ({ errors }: ErrorListProps) => {
    if (errors.length > 0) {
        return (
            <div>
                <ul>
                    {errors?.map((x: string, index: number) => (
                        <li key={index}>{x}</li>
                    ))}
                </ul>
            </div>
        )
    } else {
        return null
    }

};
