import { useState } from "react"
import { apiURL } from "../../../constants"
import { ITrainingSpotPictureProps } from "../../Interfaces"

export const TrainingSpotPicture = ({ pictureId }: ITrainingSpotPictureProps) => {
    const [isLoaded, setIsLoaded] = useState(false)
    const thumbnailPath = pictureId.slice(0, pictureId.lastIndexOf('.')) +
    "-thumbnail" + pictureId.slice(pictureId.lastIndexOf('.'));

    return (
        <img 
            src={`${apiURL}/api/Images/${thumbnailPath}`}
            onLoad={() => { setIsLoaded(true) }}
            onError={() => { setIsLoaded(true) }}
            style={isLoaded ? { height: '4rem' } : { height: '4rem', backgroundColor: 'black' }}
            alt='Traing spot'
        />
    )
}