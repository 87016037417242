import { IReview, IUserJWT } from "../../Interfaces";
import { Link } from "react-router-dom";

interface IReviewProps {
    review: IReview
    user: IUserJWT
    handleDelete?: (id: string) => Promise<void>
    handleUpdateFormData?: (review: IReview) => void
    viewLabel: string;
    editLabel: string;
    deleteLabel?: string;
    ratingLabel: string
}

export const MyReview = ({ review, user, handleDelete, handleUpdateFormData, viewLabel, deleteLabel, editLabel, ratingLabel }: IReviewProps) => {
    return (
        <div key={review.id} style={{ display: 'flex', gap: '0.25rem', alignItems: 'center' }}>
            <p key={review.id}>{review.dateWritten.slice(0, 10)} - {review.comment} - {ratingLabel} {review.rating}</p>
            {handleUpdateFormData && review.userId === user?.sub && <button onClick={() => handleUpdateFormData(review)}>{editLabel}</button>}
            {handleDelete && review.userId === user?.sub && <button onClick={() => { handleDelete(review.id) }}>{deleteLabel}</button>}
            <Link to={`/${review.trainingSpotId}`}>{viewLabel}</Link>
        </div>
    )
}