import React, { createContext, useContext, useState } from 'react';
import { GpsLocation, GpsLocationProviderProps } from '../Interfaces';
import { initialGpsData } from '../Data/InitialData';


// Create the context
const LocationContext = createContext<[GpsLocation, React.Dispatch<React.SetStateAction<GpsLocation>>] | undefined>(undefined);

// Create a provider component
export const GpsLocationProvider: React.FC<GpsLocationProviderProps> = ({ children }) => {
    const [gpsLocation, setGpsLocation] = useState<GpsLocation>(initialGpsData);

    return (
        <LocationContext.Provider value={[gpsLocation, setGpsLocation]}>
            {children}
        </LocationContext.Provider>
    );
};

// Create a custom hook to use the location context
export const useGpsLocation = () => {
    const context = useContext(LocationContext);
    if (context === undefined) {
        throw new Error('useLocation must be used within a GpsLocationProvider');
    }
    return context;
};
