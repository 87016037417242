import { ChangeEvent, FormEventHandler } from "react"
import { IReview } from "../../Interfaces"

interface IAddNewReviewForm {
    handleSubmit: FormEventHandler<HTMLFormElement> | undefined,
    handleEdit?: any,
    handleChange: (arg0: ChangeEvent<HTMLInputElement>, arg1: any, arg2: any) => void,
    setReviewFormData: any,
    reviewFormData: IReview | undefined,
    setEditing: any,
    editing: boolean
    ratingLabel: string;
    headerLabel: string;
    submitLabel: string;
    cancelLabel: string;
}

export const AddNewReviewForm = ({
  handleSubmit,
  handleEdit,
  handleChange,
  setReviewFormData,
  reviewFormData,
  setEditing,
  editing,
  headerLabel,
  ratingLabel,
  submitLabel,
  cancelLabel
}: IAddNewReviewForm) => {
  return (
    <div>
      <form
        onSubmit={editing ? handleEdit : handleSubmit}
        style={{ display: "flex", flexDirection: "column", gap: "10px" }}
      >
        <p>{headerLabel}:</p>
        <input
          value={reviewFormData?.comment}
          type="text"
          name="comment"
          onChange={(e) => {
            handleChange(e, setReviewFormData, reviewFormData);
          }}
        />
        <div style={{ display: "flex", gap: "0.75rem" }}>
          <p>{ratingLabel}</p>
          {[1, 2, 3, 4, 5].map((x: number) => {
            return (
              <div key={x}>
                <input
                  type="radio"
                  name="rating"
                  value={x}
                  // eslint-disable-next-line
                  checked={reviewFormData?.rating == x}
                  onChange={(e) => {
                    handleChange(e, setReviewFormData, reviewFormData);
                  }}
                />
                <label htmlFor="rating">{x}</label>
              </div>
            );
          })}
          <button type="submit">{submitLabel}</button>
          {editing && (
            <button
              onClick={() => {
                setEditing(false);
              }}
            >
              {cancelLabel}
            </button>
          )}
        </div>
      </form>
    </div>
  );
};