import { createContext, useContext, useEffect, useState } from 'react';
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router';


interface AuthContextType {
    user: any | null;
    token: string | null;
    login: (token: string) => void;
    logout: () => void;
    isLoggedIn: boolean;
}
// Create a context to store authentication state
const AuthContext = createContext<AuthContextType | undefined>(undefined);

// Custom hook to access authentication context
export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};
// Authentication provider component
export const AuthProvider = ({ children }: any) => {
    const [user, setUser] = useState<any | null>(null);
    const [token, setToken] = useState<string | null>(null);
    const [loading, setLoading] = useState(true); // Add loading state
    const navigate = useNavigate()

    // Check if user is already logged in on component mount
    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            const decodedToken: any = jwtDecode(token);
            // Check if token is expired
            if (decodedToken.exp * 1000 > Date.now()) {
                setUser(decodedToken);
                setToken(`Bearer ${token}`)
            } else {
                localStorage.removeItem('token');
                setToken(null)
                setUser(null)
            }
        }
        setLoading(false); // Set loading to false after attempting to load the token
    }, [navigate]);

    if (loading) { // If loading, don't render the children
        return null;
    }

    // Function to handle user login
    const login = (token: string) => {
        localStorage.setItem('token', token);
        const decodedToken = jwtDecode(token);
        setUser(decodedToken);
    };

    // Function to handle user logout
    const logout = () => {
        localStorage.removeItem('token');
        setToken(null)
        setUser(null);
    };

    return (
        <AuthContext.Provider value={{ user, login, logout, isLoggedIn: user !== null, token }}>
            {children}
        </AuthContext.Provider>
    );
};
